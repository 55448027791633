export type PlatformRoute = { display: string; link: string };
export const BASEPLATFORM_URL = "https://dashboard.reformdao.com";

export const platformRoutes: PlatformRoute[] = [
  { display: "Overview", link: BASEPLATFORM_URL + "/" },
  { display: "Trading", link: BASEPLATFORM_URL + "/trading" },
  { display: "Bonds", link: BASEPLATFORM_URL + "/bonds" },
  { display: "Staking", link: BASEPLATFORM_URL + "/staking" },
  { display: "Voting", link: BASEPLATFORM_URL + "/voting" },
  { display: "Novus", link: BASEPLATFORM_URL + "/novus" },
  // { display: "Airdrop Checker", link: BASEPLATFORM_URL + "/airdrop-checker" },
  // { display: "Quest Platform", link: BASEPLATFORM_URL + "/quest-platform" },
];
